// ドキュメントのコピー種別
export const DOCUMENT_COPY_TYPE = {
  FROM_DETAIL: "from_detail", // 詳細からコピー
  FROM_LIST: "from_list", // 一覧からコピー
  DUPLICATE: "duplicate", // 複製
  ONLY_COMPANY: "only_company" // 自社情報の設定
} as const;

// ドキュメントの作成種別
export const CREATE_TYPE = {
  NEW: "new", // 新規・複製
  COPY_ESTIMATE: "copy_estimate", // 見積書から作成
  COPY_DELIVERY_SLIP: "copy_delivery_slip", // 納品書から作成
  COPY_INVOICE: "copy_invoice" // 請求書から作成
} as const;

export const ZBA_BANK_CODE = "9998";

// CSV出力の文字コード
export const CSV_CHAR_CODE = {
  UTF8: "UTF8", // UTF-8（BOM付き）
  UTF8N: "UTF8N", // UTF-8（BOM無し）
  SJIS: "SJIS"
} as const;

// 受取型の作成種別
export const RECEIVE_SAVE_TYPE = {
  ONLY_RECEIVE: "only", // 帳票のみ保存する
  WITH_BOTH: "with_both", // 帳票・取引先・振込先を保存する
  WITH_CUSTOMER: "with_customer", // 帳票・取引先を保存する
  WITH_ACCOUNT: "with_account" // 帳票・振込先を保存する
} as const;

// プランID
export const PLAN_ID = {
  NONE: 0,
  ISSUE_PLAN: 1, // 発行のみプラン
  ISSUE_RECEIVE_PLAN1: 2, // 基本プラン
  ISSUE_RECEIVE_PLAN2: 3, // しっかりプラン
  ISSUE_RECEIVE_PLAN3: 4 // たっぷりプラン
} as const;

// BAユーザータイプ
export const BA_USER_TYPE = {
  COMPANY_OWNER: "company_owner", // 企業オーナー
  COMPANY_USER: "company_user", // ビジネスユーザー
  SYSTEM_ADMIN: "system_admin", // システム管理者
  BANK_ADMIN: "bank_admin", // 金融機関（事務局）
  BRANCH_ADMIN: "branch_admin", // 金融機関（支店）
  BRANCH_USER: "branch_user" // 金融機関（支店）ユーザー
};

// TODO: バックエンドと共通管理するように修正する
// ユーザーロール
export const USER_ROLE = {
  INVALID: 0, // 自身が有効なユーザーでない場合にデフォルト値の0で設定される
  COMPANY_OWNER: 1, // 企業オーナー
  COMPANY_USER: 2 // ビジネスユーザー
};

// 適格請求書発行事業者の登録番号の確認結果
export const CHECK_REGISTERED_NO_RESULT_STATUS = {
  MATCHED: 1, // 一致
  UNMATCHED: 2, // 不一致
  NOT_FOUND: 3, // 存在しない
  ERROR: 4 // エラー
};

// 電子帳簿保存法の事務処理規程ひな型DLのアクション
export const DENCHO_KITEI_ACTIONS = {
  DENCHO_KITEI_HOJIN: "dencho_kitei_hojin"
};

// AI-OCRの処理ステータス
export const AI_OCR_STATUS = {
  SUCCESS: 0, // 成功
  ERROR: 1, // エラー
  CANCELED: 2, // キャンセル
  TIMEOUT: 3, // タイムアウト
  EMPTY: 4 // 結果なし
} as const;

// メンテナンスのAPIレスポンス
export const MAINTENANCE_RESPONSE = {
  STATUS_CODE: 503,
  STATUS: "maintenance",
  SCOPE: "chanto"
};

// 品目と小計の端数処理種別
export const ROUNDING_TYPE = {
  FLOOR: 1, // 切り下げ
  TRUNCATE: 2, // 切り捨て
  CEILING: 3, // 切り上げ
  ROUND: 4 // 四捨五入
} as const;
